<template>
  <v-app class="fill-height expand-cancellation guest-page">
    <EnvironmentIndicator />
    <div class="guest-header">
      <div class="guest-header__inner container">
        <div v-if="!getLogoUrl()" class="guest-header__name">
          {{ op(reservation, 'company/name') }}
        </div>
        <div v-if="getLogoUrl()" class="guest-header__view">
          <div
            class="guest-header__logo"
            :style="`background-image:url(${getLogoUrl()})`"
          />
        </div>
      </div>
    </div>
    <v-form
      v-if="isFeedbackComplete === false"
      ref="customer-form"
      :lazy-validation="true"
    >
      <v-layout row wrap sheet>
        <v-flex xs12 text-xs-center>
          <br />
          <h1 class="cancel-reservation-title">
            A support ticket has been opened
          </h1>
        </v-flex>

        <v-flex xs4 />
        <v-flex xs4 text-xs-center>
          <p>
            Please provide us with the following information so we may quickly
            respond to your issue as best as possible.
          </p>
        </v-flex>
        <v-flex xs4 />

        <v-flex xs4 />
        <v-flex xs4 text-xs-center>
          <p>
            Again, we want to thank you for working with
            {{ op(reservation, 'companyName') }}.
          </p>
          <br />
          <br />
        </v-flex>
        <v-flex xs4 />

        <v-flex xs4 />
        <v-flex xs4>
          <CRInput
            v-model="name"
            label="Your Name"
            :rules="
              isSubmitted
                ? [
                    isRequired(true, isNotEmpty, {
                      req: 'Your Name Is Required',
                      error: 'Your Name Is Required',
                    }),
                  ]
                : []
            "
          />
        </v-flex>
        <v-flex xs4 />

        <v-flex xs4 />
        <v-flex xs4>
          <CRInput
            v-model="relationship"
            label="Relationship to Cardholder"
            :rules="
              isSubmitted
                ? [
                    isRequired(true, isNotEmpty, {
                      req: 'Relationship to Cardholder Is Required',
                      error: 'Relationship to Cardholder Is Required',
                    }),
                  ]
                : []
            "
          />
        </v-flex>
        <v-flex xs4 />

        <v-flex xs4 />
        <v-flex xs4>
          <CRSelect
            v-model="reason"
            label="Reason For Complaint"
            :items="complaintReasons"
            :rules="
              isSubmitted
                ? [
                    isRequired(true, isNotEmpty, {
                      req: 'Reason For Complaint Is Required',
                      error: 'Reason For Complaint Is Required',
                    }),
                  ]
                : []
            "
          />
        </v-flex>
        <v-flex xs4 />

        <v-flex xs4 />
        <v-flex xs4>
          <CRInput
            v-model="commentsOnExperience"
            multi-line
            label="Comment On Your Experience"
            maxlength="250"
            :rules="
              isSubmitted
                ? [
                    isRequired(true, isNotEmpty, {
                      req: 'Comments Are Required',
                      error: 'Comments Are Required',
                    }),
                  ]
                : []
            "
          />
          <small class="float-right comment-count">
            {{
              commentsOnExperience ? +(250 - commentsOnExperience.length) : 250
            }}
            characters remain.
          </small>
        </v-flex>
        <v-flex xs4 />

        <v-flex xs4 />
        <v-flex xs4 text-xs-center>
          <v-btn
            class="btn-primaryaction"
            :disabled="isFeedbackComplete"
            @click="submitForm"
          >
            Submit
          </v-btn>
        </v-flex>
        <v-flex xs4 />
      </v-layout>
    </v-form>
    <v-layout v-if="isFeedbackComplete === true" row wrap sheet>
      <v-flex xs12 text-xs-center>
        <br />
        <h1 class="cancel-reservation-title">
          Your ticket response as been sent
        </h1>
        A reservation support specialist will be in touch with you shortly.
      </v-flex>
    </v-layout>
  </v-app>
</template>
<script>
import op from 'simple-object-path'
import {
  isNotEmpty,
  isRequired,
  validateEmail,
  isNumber,
} from '@/utils/validators'
import EnvironmentIndicator from '@/components/EnvironmentIndicator.vue'
const complaintReasons = [
  'The bus was late',
  'Poor vehicle conditions',
  'Issue with the driver(s)',
  'Insufficient amenities',
  'Other',
]
export default {
  components: { EnvironmentIndicator },
  data() {
    return {
      op,
      isNotEmpty,
      isRequired,
      validateEmail,
      isNumber,
      complaintReasons,
      name: undefined,
      relationship: undefined,
      reason: undefined,
      commentsOnExperience: undefined,
      reservation: undefined,
      isFeedbackComplete: false,
      isSubmitted: false,
      complaintTicketId: undefined,
    }
  },
  async mounted() {
    const { hash, complaintTicketId } = this.$route.params
    this.complaintTicketId = complaintTicketId
    const reservationData = await this.$store
      .dispatch('reservations/simpleReservations', hash)
      .catch((e) => false)
    this.reservation = reservationData?.data
  },
  methods: {
    getLogoUrl() {
      const lightLogoUrl =
        this.reservation?.companyCheckoutPageLightLogoUrl ||
        this.reservation?.companyLightLogoUrl
      if (lightLogoUrl) {
        return lightLogoUrl
      }
      return null
    },
    submitForm() {
      this.isSubmitted = true
      this.$nextTick(async () => {
        await this.submitFeedback()
      })
    },
    async submitFeedback() {
      const isValid = this.$refs['customer-form'].validate()
      if (!isValid) {
        return
      }
      const { reservationId } = this.reservation
      const ticketId = this.complaintTicketId
      const comments = this.stringifyForm()
      const payload = {
        ticketId,
        subTickets: [{ comments }],
      }
      const params = {
        reservationId,
        payload,
      }
      await this.$store
        .dispatch('reservations/complaintFeedback', params)
        .catch((e) => false)
      this.isFeedbackComplete = true
      this.$forceUpdate()
    },
    stringifyForm() {
      return `Name: ${this.name}
Relationship to cardholder: ${this.relationship}
Reason for complaint: ${this.reason}
Comments on experience: ${this.commentsOnExperience}`
    },
  },
}
</script>
<style lang="scss">
.cancel-reservation-title {
  padding-top: 20px;
}

.guest-header {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  color: $white;
  background: $blue-darker;
  background-image: linear-gradient(to right, $blue-darker, $blue-dark);

  &__inner {
    flex: 1 1 auto;
    max-height: 100px;
    padding: 15 30;
    background-image: url('~@/assets/images/map.svg');
    background-repeat: no-repeat;
    background-position: center -125px;
    background-size: cover;
  }

  &__name {
    padding: 15 0;
    font-size: 30px;
  }

  &__view {
    width: 180px;
  }

  &__logo {
    width: 100%;
    padding-top: 25%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
  }
}

.expand-cancellation {
  background-color: $white;

  &__body {
    max-width: 400px;
  }

  &__description {
    text-align: center;

    & + & {
      margin-top: 30;
    }
  }

  &__form {
    max-width: 400px;
    padding: 0 15;
    margin: (30 * 1.5) auto 0;

    .form-group + .form-group {
      margin-top: 15 * 1.5;
    }
  }

  &__radios {
    margin-top: 10;

    > * {
      margin-left: 30;
    }
  }

  &__actions {
    display: flex;
    justify-content: center;
    margin-top: 30 * 2;
  }
}

.comment-count {
  margin-top: -20px;
}
</style>
