var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{staticClass:"fill-height expand-cancellation guest-page"},[_c('EnvironmentIndicator'),_c('div',{staticClass:"guest-header"},[_c('div',{staticClass:"guest-header__inner container"},[(!_vm.getLogoUrl())?_c('div',{staticClass:"guest-header__name"},[_vm._v(" "+_vm._s(_vm.op(_vm.reservation, 'company/name'))+" ")]):_vm._e(),(_vm.getLogoUrl())?_c('div',{staticClass:"guest-header__view"},[_c('div',{staticClass:"guest-header__logo",style:(`background-image:url(${_vm.getLogoUrl()})`)})]):_vm._e()])]),(_vm.isFeedbackComplete === false)?_c('v-form',{ref:"customer-form",attrs:{"lazy-validation":true}},[_c('v-layout',{attrs:{"row":"","wrap":"","sheet":""}},[_c('v-flex',{attrs:{"xs12":"","text-xs-center":""}},[_c('br'),_c('h1',{staticClass:"cancel-reservation-title"},[_vm._v(" A support ticket has been opened ")])]),_c('v-flex',{attrs:{"xs4":""}}),_c('v-flex',{attrs:{"xs4":"","text-xs-center":""}},[_c('p',[_vm._v(" Please provide us with the following information so we may quickly respond to your issue as best as possible. ")])]),_c('v-flex',{attrs:{"xs4":""}}),_c('v-flex',{attrs:{"xs4":""}}),_c('v-flex',{attrs:{"xs4":"","text-xs-center":""}},[_c('p',[_vm._v(" Again, we want to thank you for working with "+_vm._s(_vm.op(_vm.reservation, 'companyName'))+". ")]),_c('br'),_c('br')]),_c('v-flex',{attrs:{"xs4":""}}),_c('v-flex',{attrs:{"xs4":""}}),_c('v-flex',{attrs:{"xs4":""}},[_c('CRInput',{attrs:{"label":"Your Name","rules":_vm.isSubmitted
              ? [
                  _vm.isRequired(true, _vm.isNotEmpty, {
                    req: 'Your Name Is Required',
                    error: 'Your Name Is Required',
                  }),
                ]
              : []},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('v-flex',{attrs:{"xs4":""}}),_c('v-flex',{attrs:{"xs4":""}}),_c('v-flex',{attrs:{"xs4":""}},[_c('CRInput',{attrs:{"label":"Relationship to Cardholder","rules":_vm.isSubmitted
              ? [
                  _vm.isRequired(true, _vm.isNotEmpty, {
                    req: 'Relationship to Cardholder Is Required',
                    error: 'Relationship to Cardholder Is Required',
                  }),
                ]
              : []},model:{value:(_vm.relationship),callback:function ($$v) {_vm.relationship=$$v},expression:"relationship"}})],1),_c('v-flex',{attrs:{"xs4":""}}),_c('v-flex',{attrs:{"xs4":""}}),_c('v-flex',{attrs:{"xs4":""}},[_c('CRSelect',{attrs:{"label":"Reason For Complaint","items":_vm.complaintReasons,"rules":_vm.isSubmitted
              ? [
                  _vm.isRequired(true, _vm.isNotEmpty, {
                    req: 'Reason For Complaint Is Required',
                    error: 'Reason For Complaint Is Required',
                  }),
                ]
              : []},model:{value:(_vm.reason),callback:function ($$v) {_vm.reason=$$v},expression:"reason"}})],1),_c('v-flex',{attrs:{"xs4":""}}),_c('v-flex',{attrs:{"xs4":""}}),_c('v-flex',{attrs:{"xs4":""}},[_c('CRInput',{attrs:{"multi-line":"","label":"Comment On Your Experience","maxlength":"250","rules":_vm.isSubmitted
              ? [
                  _vm.isRequired(true, _vm.isNotEmpty, {
                    req: 'Comments Are Required',
                    error: 'Comments Are Required',
                  }),
                ]
              : []},model:{value:(_vm.commentsOnExperience),callback:function ($$v) {_vm.commentsOnExperience=$$v},expression:"commentsOnExperience"}}),_c('small',{staticClass:"float-right comment-count"},[_vm._v(" "+_vm._s(_vm.commentsOnExperience ? +(250 - _vm.commentsOnExperience.length) : 250)+" characters remain. ")])],1),_c('v-flex',{attrs:{"xs4":""}}),_c('v-flex',{attrs:{"xs4":""}}),_c('v-flex',{attrs:{"xs4":"","text-xs-center":""}},[_c('v-btn',{staticClass:"btn-primaryaction",attrs:{"disabled":_vm.isFeedbackComplete},on:{"click":_vm.submitForm}},[_vm._v(" Submit ")])],1),_c('v-flex',{attrs:{"xs4":""}})],1)],1):_vm._e(),(_vm.isFeedbackComplete === true)?_c('v-layout',{attrs:{"row":"","wrap":"","sheet":""}},[_c('v-flex',{attrs:{"xs12":"","text-xs-center":""}},[_c('br'),_c('h1',{staticClass:"cancel-reservation-title"},[_vm._v(" Your ticket response as been sent ")]),_vm._v(" A reservation support specialist will be in touch with you shortly. ")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }